<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>Cam kết bảo mật</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/masalternativamexico/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`En virtud de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “Ley”) y su legislación secundaria, a nosotros (“a nosotros”), pone a su disposición el presente Aviso de Privacidad.

Permisos para evaluar su elegibilidad y facilitar el desembolso más rápido de su préstamo, necesitamos algunos permisos para acceder a su teléfono: contactos, sms, cámara puede comunicarse con nuestro centro de atención al cliente para eliminar su información personal en cualquier momento
Todas las transacciones están protegidas por encriptación SSL de 128 bits. Los datos se transferirán y cargarán a través de una conexión segura

Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:

El consentimiento expreso del Titular para el tratamiento de los Datos Personales será recabado a través de nuestro sitio web y/o de la aplicación móvil que ponemos a su disposición (la “App”) o por cualquier otro medio electrónico, telefónico, de video u otra tecnología, lo cual podrá suceder de manera automática y simultánea al tiempo que usted haga contacto con los mismos; lo anterior para estar en posibilidad de brindarle los servicios y/o productos que ofrece a nosotros y cumplir con las finalidades que se indican en este Aviso de Privacidad.

Contactos  list

Vemos y almacenamos sus contactos y completamos automáticamente la información de contacto durante el proceso de solicitud de préstamo para ayudar a mejorar la tasa de aprobación de la solicitud de préstamo.

a nosotros cargará y transmitirá los datos de su lista de contactos, incluidos el número de teléfono y el nombre

 El número de teléfono y el nombre cargados y transmitidos se utilizarán para gestionar los riesgos y realizar comprobaciones antifraude. Durante el uso de la aplicación, ésta envía periódicamente los datos de la lista de contactos a nuestro servidor. a nosotros nunca compartirá los datos con terceros sin su permiso. La información sólo se recogerá si usted da su consentimiento para la autorización.

Lista de aplicaciones instaladas

Recopilamos información de metadatos paracada aplicación instalada, incluido el nombre de la aplicación, el nombre del paquete, el tiempo de instalación, el tiempo de actualización, el nombre de la versión y el código de la versión de cada aplicación instalada en el dispositivo para evaluar su crédito y cifrada subirlo a en nuestro servidor y nuestro proveedor externo, aunque la app esté cerrada o no esté en uso lo hará, a fin de identificar y analizar su comportamiento y riesgo frente a préstamos múltiples para evaluar si se puede procesar el préstamo o no ayudando a prevenir fraude.

Ubicación

Recopjiale y supervise información sobre la ubicación de su dispositivo para proporcionar la facilidad de servicio de su solicitud de préstamo y también proporcione ofertas personalizadas.

Número de teléfono

Usaremos el código (IMEl) de su celular, ya que con él podemos asegurar que otros celulares no puedan reemplazarle al solicitar un préstamo, previniendo asi fraudes. No obtenemos automáticamente el número de teléfono móvill del usuario para completar la página de inicio de sesión de la aplicación, ni tampoco se almacena datos de solicitantes a los que no se les apruebe el préstamo. Cifrada subirlo a en nuestro servidor nuestro proveedor externo, a fin de identificar y analizar su comportamiento y riesgo frente a préstamos mültiples para evalua si se puede procesar el préstamo o no ayudando a prevenir fraude.

Cámara

Permite que  obtenga permisos de cámara para capturar imágenes/videos y asi ailizar el trámite de solicitud de préstamo.

Almacenamiento

Le permite cargar documentos e imágenes para la solicitud de préstamo.

Información básica del teléfono móvil

Obtener la información como tipo de celular para brindar mejor servicio.

a nosotros hace del conocimiento del Titular que deberá de informar a los respectivos titulares de los datos personales que este último le proporcione a a nosotros, sobre el tratamiento que se dará a sus datos personales y el contenido de este Aviso de Privacidad.

TRANSFERENCIA DE DATOS PERSONALES

Le informamos que sus datos personales se comparten a nivel nacional y en el extranjero con las siguientes personas, empresas, organizaciones o autoridades ajenas a nosotros para los siguientes fines:

1. Operar bajo los mismos procesos y políticas internas que las sociedades controladoras, subsidiarias o afiliadas controladas conjuntamente, así como nuestra empresa matriz o cualquier empresa de nuestro grupo de empresas, para crear nuestra base de datos de clientes y poder realizar un seguimiento de las solicitudes específicas de cada uno petición.

2. De acuerdo con la ley aplicable, contactar a las autoridades competentes si es necesario.

3. Con la empresa o empresas encargadas del cobro y cobranza, cuando se requiera el cobro extrajudicial o judicial.

4. Cuando la empresa sea adquirida, fusionada u otras actividades comerciales similares con otras empresas,

5. Negociar con terceros, incluidas las entidades financieras, la cesión o descuento de carteras de crédito, avales o cualquier otra forma de cartera de crédito, si es necesario exigir el cobro por vía extrajudicial o judicial.

6. Celebrar contratos con socios comerciales para comercializar productos y/o servicios en beneficio del titular, con el objeto de proporcionar a nuestros clientes bienes o servicios que puedan ser de su interés.

7. Realizar análisis de datos con socios externos para verificar la viabilidad del otorgamiento de crédito.

8.Cooperar con proveedores de fuentes de datos de terceros para proporcionar a nuestros clientes bienes o servicios que puedan ser de su interés.

EJERCICIO DE DERECHOS

Puede ejercitar cualquiera de sus derechos de Acceso, Rectificación, Cancelación u Oposición, comúnmente conocidos como “Derechos ARCO”, así como revocar su consentimiento para el tratamiento de su información personal, salvo que la conservación de dichos datos sea obligatoria por disposición legal para a nosotros.

El ejercicio de estos derechos deberá realizarse por escrito, en la dirección de a nosotros, dirigida al área de Atención al Cliente, la cual se encargará de procesar las solicitudes de datos personales y promover su protección dentro de a nosotros;

La mencionada solicitud deberá contener la siguiente información y documentación:

• Nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud, así como los documentos que acrediten su identidad o, en su caso, la representación legal.

• Cualquier otro elemento o documento que facilite la localización de los Datos Personales.

• La solicitud deberá ser firmada al final del escrito y rubricada al calce de cada una de las hojas.

• La descripción de manera clara y precisa de los Datos Personales respecto de los cuales se busca ejercer Derechos ARCO, así como el derecho o derechos que se desea ejercer, lo cual podrá hacerse en el texto del correo electrónico o en un documento adjunto escaneado y debidamente firmado al final del mismo y rubricado al calce de cada una de las hojas.

Una vez presentada la solicitud en los términos antes mencionados, a nosotros emitirá la determinación adoptada en un plazo no mayor a 20 (VEINTE) días a partir de su recepción.

Si la solicitud resulta procedente, esta se hará efectiva dentro de los 15 (QUINCE) días hábiles siguientes en la fecha en que el interesado comunique la respuesta. En caso de que la información proporcionada en su solicitud resulte errónea o insuficiente, o no se acompañen los documentos necesarios para acreditar su identidad o la representación legal correspondiente, el interesado, dentro de los 5 (CINCO) días hábiles siguientes a la recepción de su solicitud, le requerirá que subsane las deficiencias para poder dar trámite a la misma. En estos casos, usted contará con 10 (DIEZ) días hábiles para atender el requerimiento de subsanación, contados a partir del día siguiente en que lo hubiere recibido. La solicitud correspondiente se tendrá por no presentada si Usted no responde dentro de dicho plazo.

El Titular será el responsable de mantener actualizados sus Datos Personales en posesión del responsable; por lo anterior, el Titular garantiza y responde, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales facilitados y se compromete a mantenerlos debidamente actualizados, comunicando cualquier cambio al Responsable.

Si la solicitud de ejercicio de Derechos ARCO es acerca del ejercicio del derecho de acceso, a nosotros pondrá a su disposición la información o Datos Personales a través de copias simples y/o documentos electrónicos en la dirección electrónica que el Titular señale para ello. El Titular de los Datos Personales solamente podrá revocar el consentimiento para el tratamiento de los mismos conforme al procedimiento antes señalado y siempre que así lo permita le legislación aplicable al Responsable.

a nosotros, podrá negar el ejercicio de Derechos ARCO en los supuestos que lo permita la Ley y su reglamento.

El Titular podrá revocar su consentimiento para el tratamiento de sus Datos Personales, sin efectos retroactivos, en todos aquellos casos en que dicha revocación no suponga la imposibilidad de cumplir obligaciones derivadas de una relación jurídica vigente entre el Titular y el Responsable o cuando se trate de finalidades no necesarias. El procedimiento para la revocación del consentimiento, en su caso, será el mismo que el establecido en el apartado inmediato anterior para el ejercicio de los derechos ARCO.

MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES

El Titular de los Datos Personales, podrá limitar el uso o divulgación de los mismos conforme al procedimiento previsto en la sección “Ejercicio de Derechos de ARCO”.

USO DE COOKIES Y OTRAS TECNOLOGÍAS SIMILARES

Se le informa que a nosotros utiliza mecanismos de comunicación electrónicos, ópticos u otros, remotos o locales, que nos permiten recopilar datos personales relacionados con sus actividades en medios y servicios electrónicos.

Una cookie es un fragmento de información que nuestro servidor web puede enviar a su computadora cuando visita nuestros medios electrónicos. Las cookies nos ayudan a identificarlo como usuario cuando vuelve a visitar los medios electrónicos. Puede eliminar, bloquear o recibir avisos sobre las cookies en los navegadores de Internet más habituales. Si desea hacerlo, debe consultar las instrucciones de su navegador y/o sus pantallas de ayuda, sin embargo, las cookies le permitirán aprovechar las funciones más beneficiosas que le ofrecen nuestros medios electrónicos. Los medios electrónicos pueden contener enlaces a sitios web de terceros que no están afiliados a las Partes interesadas, por lo que las Partes interesadas no asumen ninguna propiedad, obligación o responsabilidad.

CONSENTIMIENTO

Se entenderá que al utilizar nuestros Medios Electrónicos y/o contratar los Servicios que ofrecemos para las finalidades aquí previstas, expresamente reconoce y acepta el presente Aviso, por lo que dicho consentimiento nos otorga la facultad para proceder al tratamiento de los mismos en la forma en que se señala en el presente Aviso. Si tiene alguna duda acerca de nuestro Aviso, por favor envíe un correo electrónico.

DISPONIBILIDAD DEL AVISO DE PRIVACIDAD

El Titular en todo momento podrá consultar el Aviso de Privacidad, incluso con posterioridad a haberlo aceptado, en nuestro sitio web y en la App.

CAMBIOS AL AVISO DE PRIVACIDAD

El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas, las cuales haremos de su conocimiento mediante publicación en nuestro sitio web y en la App.

Cambios al Aviso de Privacidad

En cualquier momento a nosotros podrá hacer modificaciones, cambios y/o actualizaciones al presente Aviso que sean necesarias para atender disposiciones legales aplicables, nuestras prácticas de privacidad, o por otras causas, las cuales haremos de su conocimiento mediante publicación en nuestro sitio web y en la App.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  /* transform: scale(90%); */
  transform: scale(90%);
  margin: -220px auto 100px;
  height: 5200px;
  box-shadow:0 8px 14px rgba(0, 0, 0, 0.25);
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
.img1{
  margin-top: 50px;
  /* padding: 0 200px; */
}
</style>